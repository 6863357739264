<template>
  <DefaultLayout :fullWidth="true">
    <Form
      class="resetPasswordForm"
      @submit="onSubmit"
      :validation-schema="resetPasswordSchema"
      @invalid-submit="onInvalidSubmit"
    >
      <h1>Reset password</h1>
      <div class="formItem">
        <AppPasswordInput
          withIcon
          name="password"
          placeholder="Password"
          label="Password"
          type="password"
          required
        />
      </div>
      <div class="formItem mb-0">
        <AppPasswordInput
          withIcon
          name="confirmPassword"
          placeholder="Confirm Password"
          label="Confirm Password"
          type="password"
          required
        />
      </div>
      <AppButton
        align="right"
        class="submit-btn btnSubmit app-btn"
        type="submit"
        >Submit</AppButton
      >
    </Form>
  </DefaultLayout>
</template>

<script>
import { useRoute } from "vue-router";
import AppPasswordInput from "@/components/common/AppPasswordInput";
import AppButton from "@/components/common/AppButton";
import DefaultLayout from "@/views/layouts/default";
import { useAuthStore } from "@/store/AuthStore";
import { Form } from "vee-validate";
import * as Yup from "yup";

const authStore = useAuthStore();

export default {
  name: "ChangePassword",
  components: { DefaultLayout, AppPasswordInput, Form, AppButton },
  setup() {
    const route = useRoute();
    const resetPasswordSchema = Yup.object().shape({
      password: Yup.string()
        .min(8)
        .required()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          "Password must contain at least 8 Characters, at least one Uppercase and Lowercase, and one Number"
        ),
      confirmPassword: Yup.string()
        .required("Confirm password is a required field")
        .oneOf([Yup.ref("password")], "Passwords do not match"),
    });
    const onInvalidSubmit = () => {
      const submitBtn = document.querySelector(".app-btn");
      submitBtn.classList.add("invalid");
      setTimeout(() => {
        submitBtn.classList.remove("invalid");
      }, 1000);
    };
    const onSubmit = (values) => {
      const payload = {
        newPassword: values.password,
        token: route.params.token,
      };
      authStore.changeUserPassword(payload);
    };
    return {
      resetPasswordSchema,
      onInvalidSubmit,
      onSubmit,
    };
  },
};
</script>

<style lang="scss" scoped>
.mb-0 {
  margin-bottom: 0 !important;
}
h1,
label {
  font: normal normal 400 18px/30px Montserrat;
  color: var(--base-text-color);
}
h1 {
  font-size: 35px;
  margin-bottom: 15px;
}
p.error {
  margin-bottom: 1rem;
  color: red;
  font-size: 15px;
}
.resetPasswordForm {
  max-width: 320px;
}
.formItem {
  display: flex;
  flex-direction: column;
  max-width: 320px;
  margin-bottom: 1rem;
  input {
    padding: 5px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #ccc;
  }
}
.btnSubmit {
  margin-top: 1rem;
  display: block;
  padding: 10px 15px;
  color: #fff;
  background-color: #aaa;
  border: none;
  outline: none;
  border-radius: 5px;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    color: var(--buzz-charging-dark-blue);
    background-color: var(--buzz-charging-yellow);
  }
}
</style>
